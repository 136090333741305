<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                Add History
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>
                    <v-row>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.FirstName"
                                    label="First Name "
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.LastName"
                                    label="Last Name"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-radio-group v-model="historyData.Gender" row label="Gender">
                                <v-radio label="Male" value="male"></v-radio>
                                <v-radio label="Female" value="female"></v-radio>
                                <v-radio label="Other" value="other"></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="historyData.DateOfBirth"
                                            label="Date of Birth"
                                            clearable
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="historyData.DateOfBirth">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.Nationality"
                                    label="Nationality"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="historyData.Country"
                                    :items="countries"
                                    label="Country"
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                                    @change="getState"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="historyData.State"
                                    :items="state"
                                    label="State"
                                    clearable
                                    single-line
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>


                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.TicketNumber"
                                    label="Ticket Number"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6" md="4">
                            <v-autocomplete v-model="historyData.HistoryType" :items="HistoryTypes"
                                            label="History Type"></v-autocomplete>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="historyData.ServiceID"
                                    :items="Services"
                                    chips
                                    deletable-chips
                                    label="Services"
                                    clearable
                                    multiple
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-autocomplete
                                    v-model="historyData.PackageID"
                                    :items="AirPackages"
                                    chips
                                    deletable-chips
                                    label="Package"
                                    clearable
                                    multiple
                                    item-text="value"
                                    item-value="id"
                            ></v-autocomplete>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.CitizenShipNumber"
                                    label="Citizenship Number"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.PassportNumber"
                                    label="Passport Number"
                                    required
                            ></v-text-field>
                        </v-col>

                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.LicenseNumber"
                                    label="License Number "
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.OtherID"
                                    label="Other Id "
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.Document"
                                    label="Document"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-switch
                                    class="col-sm-6 col-md-4"
                                    v-model="historyData.IsVerifiedDocument"
                                    label="Is Verified Document?"
                                    clearable
                            ></v-switch>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-text-field
                                    v-model="historyData.IssuedBy"
                                    label="Issued By"
                                    required
                            ></v-text-field>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="historyData.IssuedDate"
                                            label="Issued Date"
                                            clearable
                                            prepend-icon="event"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="historyData.IssuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">Cancel</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">Save</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ImmigrationHistoryForm",
        data() {
            return {
                historyData: {},
                historyFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                AirPackages: [],
                HistoryTypes: ['Adult', 'Child', 'Old'],
                FlightClasses: [],
                Issuer: ['E-Sewa'],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            }
        },
        computed: {},
        methods: {
            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            formSubmit() {
                this.historyData.IsImportFromExcel = false
                axios.post('Immigration/InsertIntoHistoryInformationAsync', this.historyData)
                    .then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "History added succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch(response => {
                        console.log(response.data)
                        this.message.status = false
                        this.message.text = 'Error Adding Service, Please Contact Admin'
                    })
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            async formData() {
                const countryList = await axios.get("Shared/CountryList");
                this.countries = countryList.data;
                const Services = await axios.get("Immigration/DDLImmigrationServiceListAsync");
                this.Services = Services.data

                const Package = await axios.get('Immigration/DDLImmigrationPackageListAsync');
                this.AirPackages = Package.data

                const FlightClasses = await axios.get("StaticValues/DDLCommonStaticValuesListAsync/101");
                this.FlightClasses = FlightClasses.data

            },
        },
        mounted() {
            this.formData();
        },
    }
</script>

<style scoped>

</style>