<template>
    <v-content>
        <v-breadcrumbs :items="snackbar.breadcrumbsItem" large></v-breadcrumbs>
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="4"></v-col>
                <!-- <div slot="table-actions" >
                     <v-btn cols="4" color="primary" @click.stop="addModal">History Form</v-btn>
                 </div>-->
                <v-file-input cols="4" v-model="formdata.import" label="Import File" outlined dense></v-file-input>
                <v-btn cols="2" class="primary" @click.stop="onImport">
                    <v-icon>publish</v-icon>
                </v-btn>
                <v-btn cols="2" class="success" @click.stop="onDownload">
                    <v-icon>get_app</v-icon>
                    Export
                </v-btn>
            </v-row>
            <v-dialog v-model="dialogDelete" max-width="500px">
                <deleteListData :deleteFormData="deleteFormData" @formResponse="formResponse"></deleteListData>
            </v-dialog>
            <v-expansion-panels multiple hover>
                <v-expansion-panel>
                    <v-expansion-panel-header>Filter</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-container fluid>
                            <v-row col="12">
                                <v-row align="start" justify="end">
                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-menu
                                                v-model="snackbar.menu1"
                                                close-on-content-click
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="serverParams.FromDate"
                                                        label="From"
                                                        clearable
                                                        prepend-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.FromDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="3">
                                        <v-menu
                                                v-model="snackbar.menu2"
                                                close-on-content-click
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                        v-model="serverParams.ToDate"
                                                        label="To"
                                                        clearable
                                                        prepend-icon="event"
                                                        readonly
                                                        v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="serverParams.ToDate">

                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="4">
                                        <v-autocomplete v-model="serverParams.Country"
                                                        :items="countries"
                                                        label="Country"
                                                        clearable
                                                        single-line
                                                        item-text="value"
                                                        item-value="value"
                                        ></v-autocomplete>
                                    </v-col>

                                    <v-col class="d-flex" cols="12" sm="2">
                                        <v-btn class="mx-2" fab dark small color="primary" @click="loadItems">
                                            <v-icon dark>send</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
            <vue-good-table :columns="columns"
                            :rows="rows"
                            mode="remote"
                            :totalRows="historyData.totalRecords"
                            :pagination-options="{ enabled: true,mode: 'records' }"
                            @on-page-change="onPageChange"
                            @on-sort-change="onSortChange"
                            @on-per-page-change="onPerPageChange"
                            styleClass="vgt-table condensed">
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field == 'datedOn'">
                        <span>{{props.row.datedOn | moment("DD-MMM-YYYY")}}</span>
                    </span>
                    <!--<span v-else-if="props.column.field == 'actions'">
                        <v-icon small class="mr-2" @click.stop="editItem(props.row, 'HistoryForm')">edit</v-icon>
                        <v-icon small @click.stop="deleteItem(props.row)">delete</v-icon>
                    </span>-->
                </template>
            </vue-good-table>
            <v-dialog v-model="snackbar.formModal"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                      persistent>
                <v-card>
                    <!-- <component :is="snackbar.dynamicComponent.current"
                        @dialogueClose="dialogueClose"
                        @formResponse="formResponse"
                        v-bind="historyData.updateItem"
             ></component>-->
                    <HistoryForm @dialogueClose="dialogueClose"
                                 @formResponse="formResponse"
                                 v-if="snackbar.createFormComponent"></HistoryForm>
                    <HistoryEditForm @dialogueClose="dialogueClose"
                                     @formResponse="formResponse"
                                     v-if="snackbar.editFormComponent"
                                     :editItemIndex="historyData.editItemIndex"></HistoryEditForm>
                </v-card>
            </v-dialog>
            <v-snackbar v-model="snackbar.snackbar"
                        color="success"
                        :timeout="snackbar.timeout"
                        top>
                {{ callbackResponse.message }}
                <v-btn dark
                       text
                       @click="snackbar.snackbar = false">
                    Close
                </v-btn>
            </v-snackbar>
        </v-container>
    </v-content>
</template>

<script>
    import axios from "axios";
    import HistoryForm from "./HistoryForm";
    import HistoryEditForm from "./HistoryEditForm";
    import deleteListData from "@/components/deleteModal";

    export default {
        name: "ImmigrationHistoryList",
        components: {
            'HistoryForm': HistoryForm,
            'HistoryEditForm': HistoryEditForm,
            deleteListData
        },
        methods: {
            formResponse(data) {
                console.log({data})
                if (data.message) {
                    this.snackbar.formModal = false
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.callbackResponse = data;
                    this.snackbar.snackbar = true
                    this.loadItems();
                } else {
                    this.snackbar.createFormComponent = false;
                    this.dialogDelete = false;
                    this.snackbar.editFormComponent = false;
                    this.snackbar.formModal = false
                }
            },
            async onImport() {
                var form = new FormData();

                form.append(
                    "formFile",
                    this.formdata.import
                );
                let ImportResponse = await axios.post("Immigration/ImportHistory", form);
                if (ImportResponse.data.success) {
                    this.loadItems();
                    this.formdata.import = null;
                } else {
                    return false;
                }
            },
            async onDownload() {
                await axios({
                    url: 'Immigration/DownloadSample',
                    method: 'POST',
                    responseType: 'blob'
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ImportImmigrationFile.xlsx'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            },
            onResponse(data) {
                if (data == true) {
                    this.dialogDelete = false
                    this.loadItems();
                } else {
                    this.dialogDelete = false
                }
            },
            dialogueClose() {
                this.historyData.editItemIndex = null
                this.snackbar.formModal = false
                this.snackbar.createFormComponent = false
                this.snackbar.editFormComponent = false
            },
            async loadItems() {
                this.snackbar.isLoading = true;
                let param = {
                    PageSize: this.serverParams.perPage,
                    PageNo: this.serverParams.page,
                    OrderType: this.serverParams.sort[0].type,
                    OrderBy: this.serverParams.sort[0].field,
                    FromDate: this.serverParams.FromDate,
                    ToDate: this.serverParams.ToDate,
                    Country: this.serverParams.Country,
                };
                const historyData = await axios.post('Immigration/GetImmigrationHistoryListAsyc', param)
                const countryList = await axios.get("Shared/CountryList");
                this.rows = historyData.data
                this.countries = countryList.data;
                this.snackbar.loading = false;
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            addModal() {
                this.snackbar.formModal = true
                this.snackbar.createFormComponent = true
            },
            editItem(item) {
                this.snackbar.formModal = true
                this.snackbar.editFormComponent = true
                this.historyData.editItemIndex = item.personInformationID
            },
            deleteItem(props) {
                this.dialogDelete = true;
                this.deleteFormData.url = "Immigration/DeleteHistoryInformationByIDAsync/" + props.personInformationID;
            },
        },
        mounted() {
            this.loadItems()
        },
        data() {
            return {
                callbackResponse: {
                    timeout: 6000
                },
                columns: [
                    {
                        label: 'Country',
                        field: 'country'
                    },
                    {
                        label: 'Arrival Male',
                        field: 'arrivalMale',
                    },
                    {
                        label: 'Arrival Female',
                        field: 'arrivalFemale',
                    },
                    {
                        label: 'Arrival Total',
                        field: 'arrivalTotal',
                    },
                    {
                        label: 'Departure Male',
                        field: 'departureMale',
                    },
                    {
                        label: 'Departure Female',
                        field: 'departureMale',
                    },
                    {
                        label: 'Departure Total',
                        field: 'departureTotal',
                    },
                    {
                        label: 'Date',
                        field: 'datedOn',
                    },
                    {label: "Actions", field: "actions"}
                ],
                rows: [],
                countries: [],
                dialogDelete: false,
                deleteFormData: [],
                formdata: [],
                historyData: {
                    totalRows: 0,
                    editItemIndex: null
                },
                serverParams: {
                    search: "",
                    columnFilters: {},
                    sort: [
                        {
                            field: "id",
                            type: "desc"
                        }
                    ],
                    page: 1,
                    perPage: 10,
                    FromDate: null,
                    ToDate: null,
                    Country: ""
                },
                snackbar: {
                    color: '',
                    snackbar: false,
                    text: '',
                    timeout: 6000,
                    isLoading: false,
                    formModal: false,
                    createFormComponent: false,
                    editFormComponent: false,
                    menu1: false,
                    menu2: false,
                    breadcrumbsItem: [
                        {
                            text: 'Dashboard',
                            disabled: false,
                            to: 'Tourist-ArrivalDashboard',
                            exact: true

                        },
                        {
                            text: 'Tourist-Arrival History Information',
                            disabled: true,
                        },
                    ],
                    dynamicComponent: {
                        current: 'HistoryForm'
                    }
                }
            };
        },
    }
</script>

<style scoped>

</style>
